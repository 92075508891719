import React from 'react'
import { Helmet } from 'react-helmet'
import Obfuscate from 'react-obfuscate'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

import Page, { Section } from '../../shared/page-commons'
import SectionHeader from '../../shared/section-header'
import * as defaults from '../../shared/markdown-defaults'
import { ACTBanner } from '../../shared/banner'
import AlignImage from '../../shared/figure-image'

const Image = styled.img`
  width: 100%;
  display: block;
`

export default function TheTeamPage(props) {
  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="The Advanced Concepts Team (ACT) mandate is to study technologies and ideas of strategical importance in long term for the European Space Agency (ESA)."
        />
        <meta
          property="og:description"
          content="The Advanced Concepts Team (ACT) mandate is to study technologies and ideas of strategical importance in long term for the European Space Agency (ESA)."
        />
        <meta
          name="twitter:description"
          content="The Advanced Concepts Team (ACT) mandate is to study technologies and ideas of strategical importance in long term for the European Space Agency (ESA)."
        />
      </Helmet>
      <ACTBanner src="/images/core/team_banner.jpg" children="info" />
      <Section textColumn>
        <SectionHeader>What is the Advanced Concepts Team?</SectionHeader>

        <Image src={withPrefix('/images/core/advanced-concepts-team.png')} />

        <defaults.p>
          The Advanced Concepts Team (ACT) is part of the ESA's Directorate of
          Technical and Quality Management (TEC-SF). Its mandate is to perform, monitor and foster
          research on advanced concpets, technologies and working methods.
          The team is essentially a channel for the study of technologies and ideas that are of
          strategical importance in the long term planning of ESA. It ultimately serves the
          function of a think tank providing decision makers the support of a
          highly multidisciplinary research group. Science and engineering
          research fellows (PhDs working at ESA for 2 years), Young Graduate
          Trainee and stagiaires form the bulk of this Team. Based at ESTEC,
          they carry out research work on advanced topics and emerging
          technologies and perform highly skilled analysis on a wide range of
          topics.
        </defaults.p>

        <defaults.hr />

        <defaults.p>
          Please direct all general communications to the ACT Office:
        </defaults.p>

        <AlignImage
          src="/images/core/actlogo.jpg"
          align="right"
          width="420px"
        />
        <defaults.h3 css="clear:none">Address</defaults.h3>
        <defaults.p>
          <b>Advanced Concepts Team</b>
          <br />
          TEC-SF, ESTEC
          <br />
          Keplerlaan 1<br />
          2201 AZ Noordwijk
          <br />
          The Netherlands
          <br />
        </defaults.p>

        <defaults.h3 css="clear:none">eMail</defaults.h3>
        <defaults.p>
          <Obfuscate email="act@esa.int"/>
        </defaults.p>

        <defaults.h3 css="clear:none">Phone</defaults.h3>
        <defaults.p>
          Tel. <Obfuscate tel="+31(0)7156 56788"/>
        </defaults.p>
      </Section>
    </Page>
  )
}
